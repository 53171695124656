import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://4a77eba44d6a08ba1d1112302b487de2@o4506241155858432.ingest.sentry.io/4506253446807552",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});